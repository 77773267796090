import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We've all been there: You define a new CSS rule, rebuild your web application, and check the browser to see that
your styling isn't being applied. Come on!`}</p>
    <p>{`Many times, there's a conflict with another CSS rule that has `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity"
      }}>{`higher specificity`}</a>{`.
Maybe you've included a third-party library that defines style rules that conflict with your own.`}</p>
    <p>{`Luckily for us web developers, a new CSS feature comes to the rescue: `}<InlineCode mdxType="InlineCode">{`CSS Cascade Layers`}</InlineCode>{`.
They allow us to strictly layer our styles and avoid conflicts. I'll show you how to use them in an Angular web
application with `}<a parentName="p" {...{
        "href": "https://sass-lang.com/"
      }}>{`SCSS`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFYe7go0uZ//8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxEE/9oACAEBAAEFAuilnU0MJzDKjM2BFE//xAAWEQEBAQAAAAAAAAAAAAAAAAABEFH/2gAIAQMBAT8BHZ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAAAARARITJRov/aAAgBAQAGPwJI2Y9FpcvR/8QAGhABAQEBAAMAAAAAAAAAAAAAAREAMSFBkf/aAAgBAQABPyEFPF7zTPiMMgKWOF3miuNY63f/2gAMAwEAAgADAAAAECgv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EDI5t//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEn/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUFhgZH/2gAIAQEAAT8QCWrGg0vWOCchQgPeSm0FPr2yYI3admJs54ZgpHY3y5//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A cake with several visible layers.",
          "title": "A cake with several visible layers.",
          "src": "/static/1d96df584f63f394c8e19a99d57ea037/e5166/pexels-dalila-dalprat-cake.jpg",
          "srcSet": ["/static/1d96df584f63f394c8e19a99d57ea037/f93b5/pexels-dalila-dalprat-cake.jpg 300w", "/static/1d96df584f63f394c8e19a99d57ea037/b4294/pexels-dalila-dalprat-cake.jpg 600w", "/static/1d96df584f63f394c8e19a99d57ea037/e5166/pexels-dalila-dalprat-cake.jpg 1200w", "/static/1d96df584f63f394c8e19a99d57ea037/b17f8/pexels-dalila-dalprat-cake.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Dalila Dalprat / pexels.com`}</em></p>
    <h2>{`What are Cascade Layers?`}</h2>
    <p>{`CSS Cascade Layers give authors more control over the cascade, which is to say: The way the browser chooses which
styling rules to apply for a specific HTML element.`}</p>
    <p>{`The new feature is defined in the CSS Module `}<a parentName="p" {...{
        "href": "https://drafts.csswg.org/css-cascade-5/#layering"
      }}>{`Cascading and Inheritance Level 5`}</a>{`.
Although the document has only "Editor's Draft" status, cascade layers are already supported
by `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-cascade-layers"
      }}>{`all modern browsers`}</a>{`. The document states:`}</p>
    <blockquote>
      <strong>Cascade layers</strong> provide a structured way to organize and balance concerns within a single origin. Rules within a single cascade layer cascade together, without interleaving with style rules outside the layer.
    </blockquote>
    <p>{`This means that we can establish various layers – starting with low-priority styles like resets and third-party libraries,
up to high-priority styles like components and utilities. Conflicts between layers are always resolved by using the
higher-priority layer styles.`}</p>
    <p>{`If we look at the steps of the cascade, we see that layers are evaluated before specificity and order of appearance are
taken into account:`}</p>
    <ol>
      <li parentName="ol">{`Origin and importance (`}<InlineCode mdxType="InlineCode">{`!important`}</InlineCode>{`)`}</li>
      <li parentName="ol">{`Context`}</li>
      <li parentName="ol">{`Element Attached styles`}</li>
      <li parentName="ol">{`Layers`}</li>
      <li parentName="ol">{`Specificity`}</li>
      <li parentName="ol">{`Order of Appearance`}</li>
    </ol>
    <p>{`Please watch the video `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=zEPXyqj7pEA"
      }}>{`“The CSS Cascade, a deep dive”`}</a>{` by Bramus Van Damme
if you want to explore the CSS cascade in more detail.`}</p>
    <h2>{`The `}<InlineCode mdxType="InlineCode">{`@layer`}</InlineCode>{` rule`}</h2>
    <p>{`In general, we create a named cascade layer and define rules within this layer using
the `}<InlineCode mdxType="InlineCode">{`@layer`}</InlineCode>{` rule:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@layer my-layer {
    // CSS rules
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can also create a named cascade layer without assigning any styles. This is used to determine the hierarchy of your
layers. A best practice is to first define the hierarchy of your layers in a single `}<InlineCode mdxType="InlineCode">{`@layer`}</InlineCode>{`
statement, followed by the style rules separated into these layers.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@layer my-layer-1, my-layer-2;

@layer my-layer-1 {
    // CSS rules
}

@layer my-layer-2 {
    // CSS rules
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Any styles not in a layer are gathered together and placed into a single anonymous layer that comes after all the declared
layers. This means that any styles declared outside of a layer will always override styles declared in a layer.`}</p>
    <p>{`I won't get into all the details about cascade layers. Please read the great article
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/css-cascade-layers/"
      }}>{`“A Complete Guide to CSS Cascade Layers”`}</a>{`.`}</p>
    <h2>{`Demo Application with Angular and SCSS`}</h2>
    <p>{`I've created an application with `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular 14`}</a>{` and `}<a parentName="p" {...{
        "href": "https://leafletjs.com/"
      }}>{`Leaflet`}</a>{`
to display an interactive map. I use SCSS for styling, as it offers great features like placeholders and mixins. Check out
`}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/cascade-layers-angular-demo"
      }}>{`my demo code here`}</a>{`.`}</p>
    <p>{`My goal for the application is simple: I want to make sure that my styling for individual components is always applied.
They should take precedence over, e.g., CSS resets and library styles. For this purpose, I've created several cascade layers
in the main `}<InlineCode mdxType="InlineCode">{`styles.scss`}</InlineCode>{` file of my application:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@use "sass:meta";

@layer reset, thirdparty, overrides;

@layer reset {
    @include meta.load-css("layers/reset/normalize");
}

@layer thirdparty {
    @include meta.load-css("node_modules/leaflet/dist/leaflet.css");
}

@layer overrides {
    @include meta.load-css("layers/overrides/leaflet");
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`I use the SASS function `}<InlineCode mdxType="InlineCode">{`load-css`}</InlineCode>{` to load the CSS from another SCSS file and include it as part
of a cascade layer. I put all user agent style resets in the lowest-priority layer, followed by the layer for the basic
styling of the interactive leaflet map and a layer for global overrides.`}</p>
    <p>{`I don't define cascade layers for the styles of specific components declared in their own SCSS file. All these styles are
automatically put into an anonymous layer that receives the highest priority. Let's look at a specific example.`}</p>
    <h2>{`Styling a Map Popup`}</h2>
    <p>{`My demo app lists my 5 favorite places in Vienna. They are marked on an interactive leaflet map. When you click on a marker,
a popup opens. The HTML template `}<InlineCode mdxType="InlineCode">{`fav-place-popup.component.html`}</InlineCode>{` of the popup component looks
like this:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<h2>{{data.name}}</h2>
<p *ngFor="let desc of data.description">{{desc}}</p>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`I've applied the following styles in `}<InlineCode mdxType="InlineCode">{`fav-place-popup.component.scss`}</InlineCode>{`:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`p {
    font-size: 0.8rem;
    margin: 0.5em 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`At the same time, the leaflet library defines the following styles for paragraphs inside of map popups:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.leaflet-popup-content p {
    margin: 18px 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Thanks to my established order of cascade layers, I don't have to worry about leaflet's styles
for `}<InlineCode mdxType="InlineCode">{`.leaflet-popup-content p`}</InlineCode>{` interfering with my component styles for `}<InlineCode mdxType="InlineCode">{`p`}</InlineCode>{`.
At the same time, Angular's `}<a parentName="p" {...{
        "href": "https://angular.io/guide/view-encapsulation"
      }}>{`view encapsulation`}</a>{` ensures that my
component's styles are not applied to all paragraphs in my application. Sweet!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`I love cascade layers! They're a powerful tool to structure your styles and avoid pesky conflicts due to specificity
or order of appearance.`}</p>
    <p>{`There's one problem remaining: Some Angular libraries (e.g. `}<a parentName="p" {...{
        "href": "https://material.angular.io/"
      }}>{`Angular Material`}</a>{`) automatically
add styles to the HTML document's `}<InlineCode mdxType="InlineCode">{`head`}</InlineCode>{` tag. So far, I haven't found a way to put these styles in
a cascade layer of my choice. I hope this issue will be addressed in the future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      